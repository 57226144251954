import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
    },
    {
        path: 'pedido',
        loadChildren: () =>
            import('modules/pedido/pedido-routing.module').then(m => m.pedidoRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'loja',
        loadChildren: () =>
            import('modules/loja/loja-routing.module').then(m => m.LojaRoutingModule),
    },
    {
        path: 'funcionario',
        loadChildren: () =>
            import('modules/funcionario/funcionario-routing.module').then(m => m.FuncionarioRoutingModule),
    },
    {
        path: 'statuspedido',
        loadChildren: () =>
            import('modules/statuspedido/statuspedido-routing.module').then(m => m.StatuspedidoRoutingModule),
    },
    {
        path: 'tipolente',
        loadChildren: () =>
            import('modules/tipolente/tipolente-routing.module').then(m => m.TipolenteRoutingModule),
    },
    {
        path: 'caixa',
        loadChildren: () =>
            import('modules/caixa/caixa-routing.module').then(m => m.CaixaRoutingModule),
    },
    {
        path: 'cliente',
        loadChildren: () =>
            import('modules/cliente/cliente-routing.module').then(m => m.ClienteRoutingModule),
    },
    {
        path: 'relatorio',
        loadChildren: () =>
            import('modules/relatorio/relatorio-routing.module').then(m => m.relatorioRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: 'tables',
        loadChildren: () =>
            import('modules/tables/tables-routing.module').then(m => m.TablesRoutingModule),
    },
    {
        path: 'version',
        loadChildren: () =>
            import('modules/utility/utility-routing.module').then(m => m.UtilityRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
